import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';


let array_files = [];
let one = 0;
let data3 = {};

let data4 = [];

const Upload = () => {
    const navigate = useNavigate();

    const renderFileList = (files)=> {
        const file_names = [];
        let count = files.length;
        let index = 0;

        [...files].forEach((file, i) => {
            file_names.push(file.name);


            if (file) {
                if (count > 1) {
                    const links = document.createElement("a");
                    const status = document.createElement("button");
                    if (array_files.length === 0) {
                        links.setAttribute("id", 0);
                        status.setAttribute("id", 0);
                    }
                    if (array_files.length % 2) {
                        links.setAttribute("id", array_files.length+index);
                        status.setAttribute("id", array_files.length+index);
                    }
                    if (!(array_files.length % 2)) {
                        links.setAttribute("id", array_files.length/2+index);
                        status.setAttribute("id", array_files.length/2+index);
                    }
                    links.setAttribute("value", file.name);
                    links.innerText = file.name;
                    status.setAttribute("value", file.name);
                    status.setAttribute("class", "rounded-button");
                    status.innerText = "delete";
                    status.onclick = function(){
                        deletefile(file.name);
                        return false;
                    };
                    document.getElementById("processfiles").appendChild(links);
                    document.getElementById("status").appendChild(status);
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        array_files.push(file.name);
                        array_files.push(encodetext(e.target.result));
                        let file_obj = {
                            name: file.name,
                            path: file.path,
                            preview: file.preview,
                            bin: encodetext(e.target.result)
                        }
                        data4.push(file_obj);
                    };

                    reader.readAsText(file);
                    index++;
                }
                else {
                    const links = document.createElement("a");
                    const status = document.createElement("button");
                    if (array_files.length === 0) {
                        links.setAttribute("id", 0);
                        status.setAttribute("id", 0);
                    }
                    if (array_files.length % 2) {
                        links.setAttribute("id", array_files.length);
                        status.setAttribute("id", array_files.length);
                    }
                    if (!(array_files.length % 2)) {
                        links.setAttribute("id", array_files.length/2);
                        status.setAttribute("id", array_files.length/2);
                    }
                    links.setAttribute("value", file.name);
                    links.innerText = file.name;
                    status.setAttribute("value", file.name);
                    status.setAttribute("class", "rounded-button");
                    status.innerText = "delete";
                    status.onclick = function(){
                        deletefile(file.name);
                        return false;
                    };
                    document.getElementById("processfiles").appendChild(links);
                    document.getElementById("status").appendChild(status);
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        array_files.push(file.name);
                        array_files.push(encodetext(e.target.result));
                        let file_obj = {
                            name: file.name,
                            path: file.path,
                            preview: file.preview,
                            bin: encodetext(e.target.result)
                        }
                        data4.push(file_obj);
                    };
                    reader.readAsText(file);
                }
            }
        })
        return file_names;
    }

    const handleClick = () => {
        const newarray = array_files.filter(element => element !== null)

        if (newarray.length > 0) {
            data3["size"] = newarray.length/2;
            let counter = 0;
            for (let i = 0; i < newarray.length; i++) {
                if (i%2) {
                    data3[counter+"-bin"] = newarray[i];
                    counter++;
                }
                else {
                    data3[counter + "-name"] = newarray[i];
                }
            }

            // TODO
            for (let i = 0; i < data3.length; i++) {
                console.log(i);
                if (i%2) {
                    const file = atob(data3[i+"-bin"]);
                    const lines = file.split('\n');
                    const line1 = lines[0].split(';')[1];
                    const line2 = lines[1].split(';')[1];
                    if (line1 == undefined || line2 == undefined) {
                        window.alert("Error while reading CSV File, SubjectID and SN is not possible to read!");
                        break;
                    }
                    else {

                    }
                }
            }

            navigate("/process_page", { state: data4 });


        }
    };
    const encodetext = (text) => {
        return btoa(text);
    };

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        const files2 = renderFileList(e.target.files);

        // for (let i = 0; i < files2.length; i++) {
        //     const links = document.createElement("a");
        //     const status = document.createElement("a");
        //     links.setAttribute("href", "/process_page")
        //     links.innerText = files2[i];
        //     status.innerText = "0 %";
        //     document.getElementById("processfiles").appendChild(links);
        //     document.getElementById("status").appendChild(status);
        // }

        setFile(e.target.files[0]);
        sessionStorage.setItem('FileName', e.target.files[0].name);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            alert('Bitte wähle eine Datei aus.');
            return;
        }

        try {
            // Lies den Inhalt der Datei als binäre Daten
            const fileReader = new FileReader();

            fileReader.onloadend = async () => {
                const binaryStr = fileReader.result;
                //const body = new FormData();
                //body.set(binaryStr);
                //body.append('file', binaryStr);
                const response = await fetch('https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/dev/uploadcsv', {
                    method: 'POST',
                    mode: 'no-cors',
                    body: binaryStr,
                    headers: {
                        "Accept": "*/*",
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "*"
                    },
                });

                // if (!response.ok) {
                //     throw new Error(`HTTP error! status: ${response.status}`);
                // }
                //
                // const result = await response.json();
                // console.log('Upload Erfolg:', result);
            };

            fileReader.readAsArrayBuffer(file);
        } catch (error) {
            console.error('Fehler beim Hochladen der Datei:', error);
        }

    };

    const [data, setData] = useState(null);
    const apiGatewayUrl = 'https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/dev/getimplantoverviewperpatient';

    useEffect(() => {
        // Diese Funktion wird automatisch aufgerufen, sobald die Komponente in den DOM eingebunden wird

        const fetchData = async () => {
            try {
                const response = await fetch(apiGatewayUrl, {
                    method: 'GET',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                else {
                    let jsonData = await response.json();

                    let parsed_body = JSON.parse(jsonData.body);

                    // sessionStorage.clear();
                    // console.log(array_files);

                    for (let i = 0; i < parsed_body.length; i++) {
                        let td1 = document.createElement("td");
                        td1.innerHTML = parsed_body[i]["patientIDbyPhysican"];
                        // let td2 = document.createElement("td");
                        // if (parsed_body[i]["status"] === 0) {
                        //     td2.innerHTML = "Implanted";
                        // }
                        // if (parsed_body[i]["status"] === 1) {
                        //     td2.innerHTML = "explanted";
                        // }
                        // if (parsed_body[i]["status"] === 2) {
                        //     td2.innerHTML = "unknown";
                        // }
                        let td3 = document.createElement("td");
                        td3.innerHTML = parsed_body[i]["timestamp"];
                        let tr = document.createElement("tr");
                        tr.appendChild(td1);
                        // tr.appendChild(td2);
                        tr.appendChild(td3);
                        let patients = document.getElementById("patients");
                        patients.appendChild(tr);
                    }
                    setData(jsonData);
                }

            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };
        fetchData();
    }, []); // Leere Abhängigkeitsliste bedeutet, dass dieser Effekt nur einmal ausgeführt wird

    // const [files3, setFiles3] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        // Fügen Sie Dateien dem State hinzu
        renderFileList(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        // setFiles3(acceptedFiles.map(file => Object.assign(file, {
        //     preview: URL.createObjectURL(file)
        // })));
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    const deletefile = (filename) => {
        if (filename) {
            let index = array_files.indexOf(filename);
            if (index !== -1) {
                // array_files.splice(index, 1);
                // array_files.splice(index+1, 1);
                array_files[index] = null;
                array_files[index+1] = null;
                if (index%2 === 0) {
                    if (index === 0) {
                        const elem = document.getElementById(0);
                        elem.style.textDecoration = "line-through";
                    }
                    else {
                        const elem = document.getElementById(index/2);
                        elem.style.textDecoration = "line-through";
                    }
                }

                console.log(document.getElementById(index));
            } else {
                console.log(index); // String wurde nicht gefunden
            }
            console.log(array_files);

        }
    };

    return (
        <Authenticator>
            {({signOut }) => (
                <div>
                    <div className="signoutbtn">
                        <button onClick={signOut}>Sign Out</button>
                    </div>
                    <div className={"page_title"}>
                        <h1>UPLOAD PAGE</h1>
                    </div>
                    <main className={"main"}>
                            <div className="flex justify-center">
                                <div className="p-4 grid row-span-3 process text-center">
                                    <h1>Upload new EASEE Log file</h1>
                                    <form onSubmit={handleSubmit}>
                                        <div className="col-span-full">
                                            <div className="mt-2 flex justify-center px-6 py-10">
                                                <div {...getRootProps()} className="text-center" style={{ borderRadius: '5px', padding: "30px", textAlign: 'center'}}>
                                                    <svg id="Ebene_2" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.4 136.93" fill={"#A5B9C3"}>
                                                        <g id="Ebene_1-2" data-name="Ebene 1">
                                                            <g>
                                                                <path className="cls-1" d="m137.26,112.7c-5.49,0-11-.18-16.48.07-3.35.15-4.29-1.08-4.22-4.28.25-10.39-.87-8.46,8.38-8.47,8.66-.01,17.31.31,25.97.21,7.81-.09,14.38-2.95,18.18-10.18,3.47-6.59,2.97-13.24-1.15-19.41-4.05-6.06-10.05-8.65-17.18-8.12-4.28.32-5.52-.85-5.63-5.37-.49-19.07-15.3-29.72-33.45-24.15-3.61,1.11-4.76.19-6.39-2.83-9.45-17.49-27.88-22.81-43.91-12.51-9.9,6.36-13.87,16.17-14.09,27.68q-.11,6.04-6.15,5.09c-14.88-2.35-27.59,7.23-28.95,21.79-1.42,15.25,9.36,27.39,24.67,27.64,7.82.13,15.65.19,23.47-.04,3.53-.11,4.59,1.08,4.48,4.53-.3,10.01.91,8-8.23,8.16-6.49.12-12.98.06-19.48.01C17.01,112.38,1.4,97.55.09,77.41c-1.29-19.84,12.21-36.84,31.72-39.54,3.04-.42,4.08-1.46,4.81-4.35C40.99,16.4,51.73,4.93,68.99,1.13c17.08-3.77,31.44,2.02,42.34,15.8,1.42,1.8,2.77,2.29,5.04,2.13,19.63-1.37,33.91,8.66,39.51,27.75.7,2.4,1.84,3.39,4.24,4.08,15.93,4.59,25.2,18.91,22.95,35.07-2.13,15.29-15.09,26.38-31.32,26.72-4.83.1-9.65.02-14.48.02Z"/>
                                                                <path className="cls-1" d="m49.95,88.56c6.02-7.17,11.1-13.24,16.2-19.3,7.29-8.65,14.57-17.3,21.91-25.9,1.13-1.32,2.01-3.8,4.42-.93,12.5,14.9,25.12,29.69,37.68,44.54.17.2.07.61.16,1.59-6.45,0-12.86.17-19.24-.07-3.45-.13-4.59.97-4.54,4.49.18,13.14-.04,26.28.15,39.42.05,3.45-.91,4.64-4.45,4.53-7.64-.24-15.31-.24-22.95,0-3.48.11-4.56-.98-4.52-4.48.18-13.14-.03-26.28.14-39.42.05-3.44-.95-4.67-4.48-4.53-6.29.25-12.6.07-20.46.07Z"/>
                                                            </g>
                                                        </g>
                                                    </svg>

                                                    <div className="mt-4 flex text-[25px] leading-6 text-[#A5B9C3]">
                                                        <p>Drag'n drop or browse</p>
                                                        <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" accept=".csv" multiple onChange={handleFileChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<button className="bg-[#A5B9C3] hover:bg-[#839299] text-[#000] font-bold py-2 px-4 rounded" type="submit">Senden</button>*/}
                                    </form>
                                </div>
                                <div className="row-span-3 border-2 border-[#A5B9C3] process">
                                    <h1 className={"text-center process-list-header"}>Process</h1>
                                    <div className="flex">
                                        <div id="processfiles" className="flex-auto w-96 p-1 ps-2 grid row-span-3 text-left">
                                            <h5>File</h5>
                                        </div>
                                        <div id="status" className="flex-auto p-1 pe-2 grid row-span-3">
                                            <h5>Buttons</h5>
                                        </div>
                                    </div>
                                    <button className={"text-center process-list-header w-[100%]"} onClick={handleClick}>Edit</button>
                                </div>
                            </div>
                        <br></br>
                        <div className="flex justify-center">
                            <table>
                                <tbody id="patients">
                                    <tr>
                                        <th className="flex-1 w-64">Patient</th>
                                        {/*<th className="flex-1 w-64">Status</th>*/}
                                        <th className="flex-1 w-64">Last data entry</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </main>
                    <div className="footer-right">
                        <p>Version: 1.1</p>
                    </div>
                </div>

            )}
        </Authenticator>
    );
};

export default Upload;