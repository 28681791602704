import {Authenticator} from "@aws-amplify/ui-react";
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

    const Dashboard = () => {

        const [embedUrl, setEmbedUrl] = useState(null);
        const [username, setUsername] = useState(null);


            useEffect(() => {
                const fetchUsername = async () => {
                    try {
                        const user = await Auth.currentAuthenticatedUser(); // Holt den aktuellen authentifizierten Benutzer
                        await Auth.currentAuthenticatedUser(); // Überprüft, ob ein Benutzer angemeldet ist
                        const attributes = await Auth.userAttributes(user); // Holt die Attribute des Benutzers
                        const emailAttribute = attributes.find(attr => attr.Name === 'email');
                        const email = emailAttribute ? emailAttribute.Value : null;
                        console.log('Fetched username (email) from Cognito:', email);

                        if (email) {
                            setUsername(email);
                        } else {
                            throw new Error('Email not found');
                        }
                    } catch (error) {
                        console.error('Error fetching user from Cognito:', error);

                    }
                };

                fetchUsername();
            }, []);

            useEffect(() => {
                async function fetchQuickSightUrl() {
                    if (!username) return;

                    try {
                        const response = await fetch('https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/dev/DashboardUrl', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': '*',
                                'Access-Control-Allow-Headers': '*',
                            },
                            body: JSON.stringify({ username })
                        });

                        if (!response.ok) {
                            const item = document.getElementById("load_dashboard");
                            item.innerHTML = response.status;
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }

                        const data = await response.json();
                        console.log('Received data:', data);

                        const bodyContent = JSON.parse(data.body);
                        console.log('Parsed body content:', bodyContent);

                        const embedUrl = bodyContent.url;
                        console.log('Extracted embed URL:', embedUrl);

                        if (embedUrl) {
                            setEmbedUrl(embedUrl);
                        } else {
                            console.error('URL not found in the response body');
                            const item = document.getElementById("load_dashboard");
                            item.innerHTML = "URL not found in the response body";
                        }
                    } catch (error) {
                        console.error('Fehler beim Abrufen der QuickSight-URL:', error);
                        const item = document.getElementById("load_dashboard");
                        item.innerHTML = "Request Timedout from Quicksight";
                    }
                }

                if (username) {
                    fetchQuickSightUrl();
                }
            }, [username]);

            if (!username) {
                return <div>Loading...</div>;
            }

        return (
            <Authenticator>
                {({signOut}) => (
                    <div>
                        <div className="signoutbtn">
                            <button onClick={signOut}>Sign Out</button>
                        </div>
                        <div className={"div_quicksight"}>
                            {embedUrl ? (
                                <div className={"iframe_quicksight"}>
                                    <iframe
                                        title="QuickSight Dashboard"
                                        src={embedUrl}
                                        allowFullScreen
                                    />
                                </div>
                            ) : (
                                <div id={"load_dashboard"}>Lade Dashboard...</div>
                            )}
                        </div>
                    </div>
                )}
            </Authenticator>
        );
    };

    export default Dashboard;